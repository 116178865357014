import React, { useState, useEffect, useContext, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import AppContext from '../context/AppContext';

const Login = () => {
    const { state, login } = useContext(AppContext);
    const { user, jwt } = state;
    const form = useRef(null);
    const history = useHistory();
    const jwtSession = window.sessionStorage.getItem('jwt');
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(form.current);
        const loginUser = {
            'email': formData.get('email'),
            'password': formData.get('password')
        }

        if(!jwtSession){
            const responseLogin = await login(loginUser);

            if(responseLogin.user.role === "client") {
                history.push('/home');
            } else if(responseLogin.user.role === "admin"){
                history.push("/clients");
            } else {
                history.push("/Login");
            }
            
        }
        
    }

    if(jwtSession) {
        history.push('/home');
    }
    return (
        <div className="account-pages my-5 pt-sm-5">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8 col-lg-6 col-xl-5">
                        <div className="card overflow-hidden">
                            <div className="bg-primary bg-soft">
                                <div className="row">
                                    <div className="col-7">
                                        <div className="text-primary p-4">
                                            <h5 className="text-primary">Welcome Back !</h5>
                                            <p>Sign in to continue to Dataverz Portal.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body pt-0">
                                <div className="auth-logo">
                                    <a className="auth-logo-dark">
                                        <div className="avatar-md profile-user-wid mb-4">
                                            <span className="avatar-title rounded-circle bg-light">
                                                <img src="https://static.wixstatic.com/media/ad0508_c6048d20209b4dbab93da85cf83a9364~mv2_d_1511_1511_s_2.png/v1/fill/w_69,h_72,al_c,q_85,usm_0.66_1.00_0.01/Dataverz-Logo-A2%20(2)_edited.webp" alt="" height="45" />
                                            </span>
                                        </div>
                                    </a>
                                </div>
                                <div className="p-2">
                                    <form className="form-horizontal" ref={form} onSubmit={handleSubmit}>

                                        <div className="mb-3">
                                            <label for="email" className="form-label">Email</label>
                                            <input type="email" name="email" className="form-control" id="email" placeholder="Enter email" />
                                        </div>

                                        <div className="mb-3">
                                            <label className="form-label">Password</label>
                                            <div className="input-group auth-pass-inputgroup">
                                                <input type="password" name="password" className="form-control" placeholder="Enter password" aria-label="Password" aria-describedby="password-addon" />
                                                <button className="btn btn-light " type="button" id="password-addon"><i className="mdi mdi-eye-outline"></i></button>
                                            </div>
                                        </div>

                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="remember-check" />
                                            <label className="form-check-label" for="remember-check">
                                                Remember me
                                </label>
                                        </div>

                                        <div className="mt-3 d-grid">
                                            <button type="submit" className="btn btn-primary waves-effect waves-light" onClick={handleSubmit}>Log In</button>
                                        </div>

                                        <div className="mt-4 text-center">
                                            <a href="auth-recoverpw.html" className="text-muted"><i className="mdi mdi-lock me-1"></i> Forgot your password?</a>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                        <div className="mt-5 text-center">

                            <div>
                                {/* <p>Don't have an account ? <a href="auth-register.html" className="fw-medium text-primary"> Signup now </a> </p> */}
                                <p>© <script>document.write(new Date().getFullYear())</script> Dataverz Aps.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;