import React, { useState, useEffect, useContext, useRef } from 'react';
import moment from 'moment'
import DataTable from 'react-data-table-component';
import AppContext from '../context/AppContext';

const NewClient = () => {
    const { state, createClient, createUser, createFeatures } = useContext(AppContext);
    const [error, setError] = useState([]);

    const form = useRef(null);
    let message = "";

    const handleSubmit = async () => {

        const formData = new FormData(form.current);

        const client = {
            "name": formData.get('name'),
            "web": formData.get('web'),
            "email": formData.get('email')
        }
        
        let responseClient = await createClient(client);
        console.log(responseClient);
        
        if(!responseClient.statusCode) {
            let clientId = responseClient.id;

            const user = {
                'name': formData.get('username'),
                'email': formData.get('userEmail'),
                'password': formData.get('password'),
                'role': 'client',
                "clientId": clientId
            }
    
            let responseUser = await createUser(user);
    
            console.log(responseUser);
    
            const features = [
                {
                    "name": "tab1",
                    "title": "tab1",
                    "description": "tab1",
                    "iframe": "",
                    "tab_name": "",
                    "clientId": clientId
                },
                {
                    "name": "tab2",
                    "title": "tab2",
                    "description": "tab2",
                    "iframe": "",
                    "tab_name": "",
                    "clientId": clientId
                },
            ]

            features.map(async(feature) => {
                console.log(feature);
                await createFeatures(feature);
            })

        } else {
            setError(responseClient.message)
        }
    }

    return (
        <div className="main-content">

            <div className="page-content">
                <div className="container-fluid">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card">
                                <div class="card-body">
                                    <form ref={form}>
                                        <h4 class="card-title mb-4">Client</h4>
                                        {
                                            (error) ?
                                                error.map((error) =>{
                                                return (
                                                    <li>{error}</li> 
                                                    )
                                                } )
                                            : 'a'
                                        }
                                        <div class="row">
                                            
                                            <div class="col-lg-6">
                                                <div >
                                                    <div class="form-group mb-4" >
                                                        <label for="input-date1">Name</label>
                                                        <input id="input-date1" name="name" class="form-control input-mask" />
                                                        <span class="text-muted">e.g "Dataverz Aps"</span>
                                                    </div>
                                                </div>
                                                <div >
                                                    <div class="form-group mb-4" >
                                                        <label for="input-date1">Web</label>
                                                        <input id="input-date1" name="web" class="form-control input-mask" />
                                                        <span class="text-muted">e.g "www.dataverz.net"</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6" >
                                                <div class="mt-4 mt-lg-0" >
                                                    <div class="form-group mb-4" >
                                                        <label for="input-date2">Email</label>
                                                        <input id="input-date2" name="email" class="form-control input-mask" />
                                                        <span class="text-muted">e.g "dataverz@dataverz.net"</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h4 class="card-title mb-4">User</h4>
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <div >
                                                    <div class="form-group mb-4" >
                                                        <label for="input-date1">username</label>
                                                        <input id="input-date1" name="username" class="form-control input-mask" />
                                                        <span class="text-muted">e.g "Dataverz Aps"</span>
                                                    </div>
                                                </div>

                                                <div class="mt-4 mt-lg-0" >
                                                    <div class="form-group mb-4" >
                                                        <label for="input-date2">Password</label>
                                                        <input id="input-date2" class="form-control input-mask" name="password" type="password" />
                                                        <span class="text-muted"></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6" >
                                                <div class="mt-4 mt-lg-0" >
                                                    <div class="form-group mb-4" >
                                                        <label for="input-date2">Email</label>
                                                        <input id="input-date2" name="userEmail" class="form-control input-mask" />
                                                        <span class="text-muted">e.g "dataverz@dataverz.net"</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div><button type="button" className="btn btn-primary w-md" onClick={handleSubmit}>Submit</button></div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NewClient;