import React, { useState, useEffect, useContext } from 'react';
import Iframe from 'react-iframe';
import AppContext from '../context/AppContext';


const Menu = () => {

    const { state, changePage } = useContext(AppContext);
    const { menu } = state;

    const handleClick = (pageActive, iframe, description) => {
        changePage({ pageActive, iframe, description } );
    }

    return (
        <div>
            { (menu) ?
            <div className="vertical-menu">

                <div data-simplebar className="h-100">

                    <div id="sidebar-menu">
                        <ul className="metismenu list-unstyled" id="side-menu">
                            <li className="menu-title" key="t-menu">Menu</li>
                            {menu.map(menuInfo => {
                                return (
                                    <li key={menuInfo.name}>
                                        <a className="waves-effect" onClick={() => handleClick(menuInfo.name, menuInfo.iframe, menuInfo.description)}>
                                            <i className="bx bx-bar-chart-alt-2"></i>
                                            <span key="t-starter-page">{menuInfo.name}</span>
                                        </a>
                                    </li>
                                )
                            })}

                        </ul>
                    </div>

                </div>
            </div>
            : 'loading ...' }        
        </div>
    );
}

export default Menu;