import React, { useState, useEffect, useContext } from 'react';
import Iframe from 'react-iframe'
import AppContext from '../context/AppContext';
import $ from 'jquery'

const Content    = () => {
    const { state } = useContext(AppContext);
    const { content } = state;
    const handleClickModal = (urlIframe) => {
        $('iframe').attr('src',urlIframe)
    }

    const handleCloseModal = () => {
        $('.modal-body iframe').attr('src',"")
    }

    return (
        <div>
            <div className="main-content">

                <div className="page-content">
                    <div className="container-fluid">


                        <div className="row">
                            <div className="col-12">

                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4>{content.pageActive}</h4>
                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a className="breadcrumb-link"><h4></h4></a></li>
                                            <li>
                                                <button data-bs-toggle="modal" data-bs-target="#fullscreen-modal" onClick={() => handleClickModal(content.iframe)} >
                                                    <i class="dripicons-expand"></i>
                                                </button>
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                                <p class="card-title-desc">{content.description}</p>
                                <div className="iframe">
                                    <Iframe url={content.iframe} className="iframe-content" /> 
                                </div>
                                <div id="fullscreen-modal" class="modal fade" tabindex="-1" aria-labelledby="#fullscreen-modalLabel" aria-hidden="true">
                                    <div class="modal-dialog modal-fullscreen">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <button onClick={handleCloseModal} type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div class="modal-body">
                                                <Iframe url="" className="iframe-content" /> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Content  ; 