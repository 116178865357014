import React, { useState, useEffect } from 'react';
import Iframe from 'react-iframe';
import LoginComponent from '../components/Login';

const Login = () => {
        
        return (
            <div>
                <LoginComponent />
            </div>
        );
}

export default Login;