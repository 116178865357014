import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Home from '../containers/Home';
import Client from '../containers/Client';
import NewClient from '../containers/NewClient';
import UpdateClient from '../containers/UpdateClient';
import Login from '../containers/Login';
import NotFound from '../containers/NotFound';

import '../App.css';
import LayoutHome from '../components/LayoutHome';
import AppContext  from '../context/AppContext';
import userInitialState from '../hooks/useInitialState';

function App() {
  const initialState = userInitialState();
  const isEmpty = Object.keys(initialState.state).length;
  return (
    <>
      <AppContext.Provider value={initialState}>
        <BrowserRouter>
            <Switch>
              <Route exact path="/" component={Login} />
              <LayoutHome>
                <Route exact path="/home" component={Home} />
                <Route exact path="/clients" component={Client} />
                <Route exact path="/newclients" component={NewClient} />
                <Route exact path="/updateclients/:id" component={UpdateClient} />
              </LayoutHome>
              <Route component={NotFound} />
            </Switch>
        </BrowserRouter>
      </AppContext.Provider>
    </>
  );
}

export default App;
