import React, { useContext } from 'react';
import AppContext from '../context/AppContext';
import { useHistory } from 'react-router-dom';
import $ from 'jquery';

const Header = () => {
    const history = useHistory();
    const { state, logout } = useContext(AppContext);
    const { user } = state;
    const handleClick = () => {
        logout();
        history.push('/');
    }

    const handleToggle = () => {
            $('body').toggleClass('vertical-collpsed');
    }

    return (
        <>
        { (user) ?
        <header id="page-topbar">
            <div className="navbar-header">
                <div className="d-flex">

                    <div className="navbar-brand-box">
                        <a href="index.html" className="logo logo-dark">
                            <span className="logo-sm">
                                <img src="https://static.wixstatic.com/media/ad0508_c6048d20209b4dbab93da85cf83a9364~mv2_d_1511_1511_s_2.png/v1/fill/w_69,h_72,al_c,q_85,usm_0.66_1.00_0.01/Dataverz-Logo-A2%20(2)_edited.webp" alt="" height="50" />
                            </span>
                            <span className="logo-lg">
                                <img src="https://static.wixstatic.com/media/ad0508_c6048d20209b4dbab93da85cf83a9364~mv2_d_1511_1511_s_2.png/v1/fill/w_69,h_72,al_c,q_85,usm_0.66_1.00_0.01/Dataverz-Logo-A2%20(2)_edited.webp" alt="" height="50" />
                            </span>
                        </a>

                        <a href="index.html" className="logo logo-light">
                            <span className="logo-sm">
                                <img src="https://static.wixstatic.com/media/ad0508_c6048d20209b4dbab93da85cf83a9364~mv2_d_1511_1511_s_2.png/v1/fill/w_69,h_72,al_c,q_85,usm_0.66_1.00_0.01/Dataverz-Logo-A2%20(2)_edited.webp" alt="" height="50" />
                            </span>
                            <span className="logo-lg">
                                <img src="https://static.wixstatic.com/media/ad0508_c6048d20209b4dbab93da85cf83a9364~mv2_d_1511_1511_s_2.png/v1/fill/w_69,h_72,al_c,q_85,usm_0.66_1.00_0.01/Dataverz-Logo-A2%20(2)_edited.webp" alt="" height="50" />
                            </span>
                        </a>
                    </div>

                    <button type="button" className="btn btn-sm px-3 font-size-16 header-item waves-effect" id="vertical-menu-btn" onClick={handleToggle} >
                        <i className="fa fa-fw fa-bars"></i>
                    </button>
                </div>

                <div className="d-flex">

                    <div className="dropdown d-inline-block">
                        <button type="button" className="btn header-item waves-effect" id="page-header-user-dropdown"
                            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <img className="rounded-circle header-profile-user" src={ (user.img_user) ? user.img_user : "assets/images/users/avatar-1.jpg" }
                                alt="Header Avatar" />
                            <span className="d-none d-xl-inline-block ms-1" key="t-henry">{user.name}</span>
                            <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                        </button>
                        <div className="dropdown-menu dropdown-menu-end">
                            <button className="dropdown-item text-danger" onClick={handleClick}><i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i> <span key="t-logout">Logout</span></button>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        : 'loading ...' }
        </>
    );
}

export default Header;