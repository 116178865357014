import React from 'react';
import { useHistory } from 'react-router-dom';

import Footer from '../components/Footer';
import Header from '../components/Header';

const LayoutHome = ({ children }) => {
  const jwtSession = window.sessionStorage.getItem('jwt');
  const history = useHistory();
  if(!jwtSession) {
    history.push('/');
  }
  return (
    <div>
      <Header />
      {children}
      <Footer />
    </div>
  );
}

export default LayoutHome;