import React, { useState, useEffect, useContext, useRef } from 'react';
import moment from 'moment'
import DataTable from 'react-data-table-component';
import AppContext from '../context/AppContext';
import { useParams } from 'react-router';

const NewClient = () => {
    
    const { state, createClient, createUser, createFeatures, clientById, featureByClientId, removeById } = useContext(AppContext);
    const [error, setError] = useState([]);
    const { id } = useParams();
    const [infoClient, setInfoClient] = useState();
    const [infoFeaturesClient, setInfoFeaturesClient] = useState();
    const [deleteFeaturesClient, setDeleteInfoFeaturesClient] = useState();

    useEffect(async () => {
        const ResponseClients = await clientById({id: id});
        const resposeFeatures = await featureByClientId({id: id})
        setInfoClient(ResponseClients);
        setInfoFeaturesClient(resposeFeatures);
        setDeleteInfoFeaturesClient([])
        console.log(resposeFeatures)
    },[])

    const parseFeature = (feature) => {
        return {
            id: (feature.id) ? feature.id : null,
            clientId: parseInt(id),
            description: (feature) ? feature.description : '',
            iframe: (feature) ? feature.iframe : '',
            name: (feature) ? feature.name : '',
            title: (feature) ?  feature.title : '',
            tab_name: (feature) ? feature.tab_name : ''
        }
    }

    const form = useRef(null);
    let message = "";

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...infoFeaturesClient];
        list[index][name] = value;
        console.log(list)
        setInfoFeaturesClient(list);
    };

    const removeFeature = (index) => {
        const newList = infoFeaturesClient.filter((item, i) => i !== index);
        console.log(infoFeaturesClient[index])
        setDeleteInfoFeaturesClient([...deleteFeaturesClient,infoFeaturesClient[index]])
        setInfoFeaturesClient(newList);
    }

    const handleAddFeature = () => {
        setInfoFeaturesClient([...infoFeaturesClient, { 
            clientId: id,
            description: "",
            iframe: "",
            name: "",
            title: "",
            tab_name: ""
        }]);
      };

    
    const handleSubmit = async () => {

        const formData = new FormData(form.current);

        const client = {
            "name": formData.get('nameClient'),
            "web": formData.get('webClient'),
            "email": formData.get('emailClient'),
            "id": id
        }
        console.log(client)
        const user = {
            'userId': formData.get('userId'),
            'name': formData.get('username'),
            'email': formData.get('userEmail'),
            'password': formData.get('password'),
            'role': 'client',
            "clientId": id
        }
        console.log(user);
        let responseClient = await createClient(client);
        console.log(responseClient);
        
        if(!responseClient.statusCode) {
            
            let clientId = responseClient.id;

            const user = {
                'userId': formData.get('userId'),
                'name': formData.get('username'),
                'email': formData.get('userEmail'),
                'password': formData.get('password'),
                'role': 'client',
                "clientId": clientId
            }
    
            let responseUser = await createUser(user);
            
            console.log(responseUser);
            
            deleteFeaturesClient.map(async(featureDelete)=> {
                if(featureDelete.id) {
                    await removeById({id: featureDelete.id, table: 'features'})
                }
            })

            infoFeaturesClient.map(async(feature) => {
                //console.log(feature);
                //console.log(parseFeature(feature))
                await createFeatures(parseFeature(feature));
            })

        } else {
            setError(responseClient.message)
        }
    }

    return (
        <div className="main-content">

            <div className="page-content">
                <div className="container-fluid">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card">
                                <div class="card-body">
                                    <form ref={form}>
                                        <h4 class="card-title mb-4">Client</h4>
                                        {
                                            (error) ?
                                                error.map((error) =>{
                                                return (
                                                    <li>{error}</li> 
                                                    )
                                                } )
                                            : 'a'
                                        }
                                        
                                        { (infoClient) ?
                                        <div class="row">
                                            
                                            <div class="col-lg-6">
                                                <div >
                                                    <div class="form-group mb-4" >
                                                        <label for="input-date1">Name</label>
                                                        <input id="input-date1" name="nameClient" class="form-control input-mask" defaultValue={infoClient.name} />
                                                        <span class="text-muted">e.g "Dataverz Aps"</span>
                                                    </div>
                                                </div>
                                                <div >
                                                    <div class="form-group mb-4" >
                                                        <label for="input-date1">Web</label>
                                                        <input id="input-date1" name="webClient" class="form-control input-mask" defaultValue={infoClient.web}/>
                                                        <span class="text-muted">e.g "www.dataverz.net"</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6" >
                                                <div class="mt-4 mt-lg-0" >
                                                    <div class="form-group mb-4" >
                                                        <label for="input-date2">Email</label>
                                                        <input id="input-date2" name="emailClient" class="form-control input-mask" defaultValue={infoClient.email} />
                                                        <span class="text-muted">e.g "dataverz@dataverz.net"</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : 'loading.. '}
                                        <h4 class="card-title mb-4">User</h4>
                                        { (infoClient) ?
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <div >
                                                <input id="input-date1" name="userId" class="form-control input-mask" type="hidden" value={infoClient.users[0].id} />
                                                    <div class="form-group mb-4" >
                                                        <label for="input-date1">username</label>
                                                        <input id="input-date1" name="username" class="form-control input-mask" defaultValue={infoClient.users[0].name} />
                                                        <span class="text-muted">e.g "Dataverz Aps"</span>
                                                    </div>
                                                </div>

                                                <div class="mt-4 mt-lg-0" >
                                                    <div class="form-group mb-4" >
                                                        <label for="input-date2">Password</label>
                                                        <input id="input-date2" class="form-control input-mask" name="password" type="password" />
                                                        <span class="text-muted"></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6" >
                                                <div class="mt-4 mt-lg-0" >
                                                    <div class="form-group mb-4" >
                                                        <label for="input-date2">Email</label>
                                                        <input id="input-date2" name="userEmail" class="form-control input-mask" defaultValue={infoClient.users[0].email} />
                                                        <span class="text-muted">e.g "dataverz@dataverz.net"</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : 'loading.. '}
                                        
                                        <a className="btn btn-primary w-md" onClick={() => handleAddFeature()}>Add new tab</a>
                                            
                                        {(infoFeaturesClient) ?
                                        <div class="row">
                                            <div class="tab-content p-3 text-muted">
                                                {infoFeaturesClient.map((featureInfo, i) => {
                                                return (
                                                    <div class="row">
                                                        
                                                    <div class="col-lg-6">
                                                        <div >
                                                            <div class="form-group mb-4" >
                                                                <label for="input-date1">Name</label>
                                                                <input id="input-date1" name="name" class="form-control input-mask"  defaultValue={featureInfo.name} onChange={e => handleInputChange(e, i)} />
                                                                <span class="text-muted">e.g "Tableau"</span>
                                                            </div>
                                                        </div>

                                                        <div class="mt-4 mt-lg-0" >
                                                            <div class="form-group mb-4" >
                                                                <label for="input-date2">Description</label>
                                                                <textarea id="textarea" class="form-control" name="description" maxlength="225" rows="3" onChange={e => handleInputChange(e, i)}>{featureInfo.description}</textarea>
                                                                <span class="text-muted"></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="tab-pane active" id="home" role="tabpanel">
                                                        <div class="form-group mb-4" >
                                                            <label for="input-date2">Iframe</label>
                                                            <textarea id="textarea" class="form-control" name="iframe" maxlength="225" rows="3" onChange={e => handleInputChange(e, i)}>{featureInfo.iframe}</textarea>
                                                        </div>
                                                        <a className="btn btn-danger waves-effect waves-light w-md" onClick={()=> removeFeature(i)}>remove feature</a>
                                                    </div>
                                                    <p></p>
                                                    <hr></hr>
                                                    </div>
                                                    
                                                )
                                                })}
                                            </div>
                                        </div>
                                        : 'loading...'}
                                        <div>
                                            <button onClick={handleSubmit} style={{float: 'right'}} type="button" className="btn btn-primary w-md">Save</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NewClient;