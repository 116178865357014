import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Menu from '../components/MenuAdmin';
import Content from '../components/NewClient';

const HomeAdmin = () => {
        
        return (
            <div>
                <Menu />
                <Content />
            </div>
        );
}

export default HomeAdmin;