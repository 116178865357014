import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment'
import DataTable from 'react-data-table-component';
import AppContext from '../context/AppContext';

const Content = () => {
    const { state, setState, clients, removeById } = useContext(AppContext);
    const [client, setClient] = useState([])
    const history = useHistory();
    const data = [];
    const columns = [
        {
          name: 'Name',
          selector: 'name',
          sortable: true,
        },
        {
          name: 'Email',
          selector: 'email',
          sortable: true,
          right: true,
        },
        {
            name: 'Web',
            selector: 'web',
            sortable: true,
            right: true,
        },
        {
            name: 'create_at',
            selector: row => moment(row.createAt).format('lll'),
            sortable: true,
            right: true,
        },
        {
            name: 'Edit',
            selector: '',
            sortable: true,
            right: true,
            cell: row =><button className="btn btn-primary waves-effect waves-light" onClick={() => handleClick(row.id)}> edit </button>
        },
        {
            name: 'Delete',
            selector: '',
            sortable: true,
            right: true,
            cell: row =><button className="btn btn-danger waves-effect waves-light" onClick={() => remove(row.id)} > delete </button>
        },
      ];
    useEffect(async () => {
        const ResponseClients = await clients();
        setClient(ResponseClients);
        console.log(client)
    },[])

    const handleClick = async (clientId) => {
        console.log(clientId);
        setState({
            ...state,
            updateUserById: clientId
        })
        history.push(`/updateclients/${clientId}`);
    }

    const remove = async (clientId) => {
        console.log(clientId)
        let response = await removeById({id: clientId, table: 'clients'})
        if(!response.statusCode) {
            const newList = client.filter((item) => item.id !== clientId);
            console.log(newList)
            setClient(newList);
        }
        
        
    }
    
    return (
        <div>
            <div className="main-content">

                <div className="page-content">
                    <div className="container-fluid">


                        <div className="row">
                            <div className="col-12">

                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0 font-size-18">Admin</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a>Clients</a></li>
                                            <li className="breadcrumb-item active">Clients</li>
                                        </ol>
                                    </div>

                                </div>
                                { (client) ?
                                <DataTable
                                    title="Clients"
                                    columns={columns}
                                    data={client}
                                />
                                : 'loading...'}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Content  ; 