import React, { useState, useEffect } from 'react';
import Menu from '../components/Menu';
import Content from '../components/Content';

const Home = () => {
        
        return (
            <div>
                <Menu />
                <Content />
            </div>
        );
}

export default Home;