import { useState, useEffect } from 'react';
import jwt_decode from "jwt-decode";

import initialState from '../initialState';

const useInitialState = () => {
    const [state, setState] = useState(initialState);
    const [jwt, setJWT] = useState(() => window.sessionStorage.getItem('jwt'))
    const [content, setContent] = useState({ pageActive: '', iframe: ''});
    const API_URL = (process.env.NODE_ENV === 'production') ? process.env.REACT_APP_API_URL : '';
    useEffect(async () => {
            if(jwt) {
                const jwtDecode = jwt_decode(jwt)
                
                const responseUsers = await users()
                if(jwtDecode.role === "client") {
                    const responseMenu = await menu()
                    setState({
                        ...state,
                        user: responseUsers,
                        menu: responseMenu,
                        content: {
                            description: responseMenu[0].description, 
                            iframe: responseMenu[0].iframe,
                            pageActive: responseMenu[0].name
                        }
                    })
                } else {
                    setState({
                        ...state,
                        user: responseUsers
                    })
                }
            }
    },[jwt,content])

    const changePage = payload => {
        
        setState({
            ...state,
            content: payload
        })
    }

    const menu = async () => {
        return fetch(`${API_URL}/profile/my-features`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${window.sessionStorage.getItem('jwt')}`, 
                'Content-type': 'application/json'
            }
        })
        .then(res => res.json())
        .then((result) => {
            if(result) {
                setState({
                    ...state,
                    menu: result,
                    content: {
                        description: result[0].description,
                        iframe: result[0].iframe,
                        pageActive: result[0].name
                    }
                })
                return result;
            }
        })
        .catch(err => {
            
        })
    }
    
    const clients = async () => {
        return fetch(`${API_URL}/clients`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${window.sessionStorage.getItem('jwt')}`, 
                'Content-type': 'application/json'
            }
        })
        .then(res => res.json())
        .then((result) => {                
                setState({
                    ...state,
                    user: result
                })
                return result
        })
        .catch(err => {
            
        })
    }

    const clientById = async payload => {
        return fetch(`${API_URL}/clients/${payload.id}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${window.sessionStorage.getItem('jwt')}`, 
                'Content-type': 'application/json'
            }
        })
        .then(res => res.json())
        .then((result) => {                
                setState({
                    ...state,
                    user: result
                })
                return result
        })
        .catch(err => {
            
        })
    }

    const featureByClientId = async payload => {
        return fetch(`${API_URL}/features/features-client/${payload.id}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${window.sessionStorage.getItem('jwt')}`, 
                'Content-type': 'application/json'
            }
        })
        .then(res => res.json())
        .then((result) => {                
                setState({
                    ...state,
                    user: result
                })
                return result
        })
        .catch(err => {
            
        })
    }

    
    const createClient = async payload => {
        let url = (payload.id) ? `${API_URL}/clients/${payload.id}` : `${API_URL}/clients`; 
        let method = (payload.id) ? 'PUT' : 'POST';
        delete payload.id
        return fetch(url, {
            method: method,
            headers: {
                'Authorization': `Bearer ${window.sessionStorage.getItem('jwt')}`,
                "Content-type": "application/json"
            },
            body: JSON.stringify(payload)
        })
        .then(res => res.json())
        .then((result) => {
            return result
        })
        .catch(err => {
            
        })
    }

    const createUser = async payload => {
        let url = (payload.userId) ? `${API_URL}/users/${payload.userId}` : `${API_URL}/users`; 
        let method = (payload.userId) ? 'PUT' : 'POST';
        if (!payload.password) delete payload.password
        delete payload.userId
        return fetch(url, {
            method: method,
            headers: {
                'Authorization': `Bearer ${window.sessionStorage.getItem('jwt')}`,
                "Content-type": "application/json"
            },
            body: JSON.stringify(payload)
        })
        .then(res => res.json())
        .then((result) => {
            return result
        })
        .catch(err => {
            
        })
    }

    const createFeatures = async payload => {
        let url = (payload.id) ? `${API_URL}/features/${payload.id}` : `${API_URL}/features`; 
        let method = (payload.id) ? 'PUT' : 'POST';
        if(!payload.id) delete payload.id
        if (payload.id) {
            delete payload.createAt;
            delete payload.updateAt;
            delete payload.id;
            delete payload.tabName;
        }
        
        return fetch(url, {
            method: method,
            headers: {
                'Authorization': `Bearer ${window.sessionStorage.getItem('jwt')}`,
                "Content-type": "application/json"
            },
            body: JSON.stringify(payload)
        })
        .then(res => res.json())
        .then((result) => {
            return result
        })
        .catch(err => {
            
        })
    }

    const removeById = async payload => {
        let url = `${API_URL}/${payload.table}/${payload.id}`; 
        let method = 'delete';
        
        return fetch(url, {
            method: method,
            headers: {
                'Authorization': `Bearer ${window.sessionStorage.getItem('jwt')}`,
                "Content-type": "application/json"
            },
            body: JSON.stringify(payload)
        })
        .then(res => res.json())
        .then((result) => {
            return result
        })
        .catch(err => {
            
        })
    }

    const users = async () => {
        return fetch(`${API_URL}/profile/my-profile`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${window.sessionStorage.getItem('jwt')}`, 
                'Content-type': 'application/json'
            }
        })
        .then(res => res.json())
        .then((result) => {                
                return result
        })
        .catch(err => {
            
        })
    }

    const login = async payload => {
        
        return fetch(`${API_URL}/auth`, {
            method: 'POST',
            headers: {
                "Content-type": "application/json"
            },
            body: JSON.stringify(payload)
        })
        .then(res => res.json())
        .then((result) => {
            result.user.access_token = result.access_token
            delete result.access_token
            window.sessionStorage.setItem('jwt', result.user.access_token);
            setJWT(result.user.access_token)
            setState({
                ...state,
                user: result,
                jwt: result.user.access_token
            })
            return result
        })
        .catch(err => {
            
            window.sessionStorage.removeItem('jwt');
        })
    }

    const logout = () => {
        window.sessionStorage.removeItem('jwt');
        setState(initialState)
    }

    return {
        logout,
        changePage,
        login,
        clients,
        createClient,
        createUser,
        createFeatures,
        clientById,
        featureByClientId,
        removeById,
        state,
        setState
    };
};

export default useInitialState;