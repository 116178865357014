import React, { useState, useEffect, useContext } from 'react';
import Iframe from 'react-iframe';
import AppContext from '../context/AppContext';


const Menu = () => {

    return (
        <div>
            <div className="vertical-menu">

                <div data-simplebar className="h-100">

                    <div id="sidebar-menu">
                        <ul className="metismenu list-unstyled" id="side-menu">
                            <li className="menu-title" key="t-menu">Menu</li>
                            <li key="list-clients">
                                <a className="waves-effect" href="/clients">
                                    <i className="bx bx-home-circle"></i>
                                    <span key="t-starter-page">Clients</span>
                                </a>
                            </li>
                            <li key="new-clients">
                                <a className="waves-effect" href="/newclients">
                                    <i className="bx bx-home-circle"></i>
                                    <span key="t-starter-page">New Clients</span>
                                </a>
                            </li>
                        </ul>
                    </div>

                </div>
            </div>        
        </div>
    );
}

export default Menu;